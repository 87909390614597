import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Copyright from '../components/Copyright';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const Airtable = require('airtable');
Airtable.configure({
    apiKey: 'patWmaXkLTotkP2r8.014b7ddc10c48ecf086d107e3e9071d9dc78aca08784335f54afff9f2b3c6432'
});
const base = Airtable.base('apphNOecxBClSMxAv');
const webhookURL = "https://hooks.airtable.com/workflows/v1/genericWebhook/apphNOecxBClSMxAv/wfluZsPO4EfoSnNUG/wtr2uvioFLpcf9Kqt"

const newDate = new Date();
const newYear = newDate.getFullYear();

const pickNames = (refreshDrawings, setDrawingNames, setProgress) => {
  fetch(webhookURL, {
    method: 'POST',
    mode: "no-cors",
    cache: "no-cache",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    },
    body: "year="+newYear
  }).then(function(response) {
    setDrawingNames(true);

    setInterval(() => {
      var currentProgress;
      setProgress((prevProgress) => {
        currentProgress = prevProgress - 1;
        return currentProgress;
      });
      if(currentProgress === 0) {
        clearInterval(this);
        refreshDrawings();
        setDrawingNames(false);
      }
    }, 1000);
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));


const TabPanel = (props) => {
  const { reloadMethod, data, value, index, ...other } = props;
  const classes = useStyles();

  const refreshDrawings = () => { reloadMethod(true); }

  const [progress, setProgress] = React.useState(10);
  const [drawingNames, setDrawingNames] = React.useState(false);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && data && (
        <Box p={3}>
        {data.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table of givers and recipients">
              <TableBody>
                {data.map((pairObj) => (
                  <TableRow key={pairObj.giver+index}>
                    <TableCell scope="row">
                      {pairObj.giver} gives to {pairObj.recipient}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!data.length && !drawingNames && (
          <Button
            onClick={() => { pickNames(refreshDrawings, setDrawingNames, setProgress) }}
            variant="contained"
            size="large">Pick Names
          </Button>
        )}
        {!data.length && drawingNames && (
          <Box position="relative" display="inline-flex">
            <CircularProgress />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
              <Typography variant="body2" component="div" color="textSecondary">{progress}</Typography>
            </Box>
          </Box>
        )}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  data: PropTypes.array,
  reloadMethod: PropTypes.func,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    key: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IndexPage = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [pastDrawings, setPastDrawings] = React.useState([]);
  const [yearMap, setYearMap] = React.useState([]);
  const [pickedNewNames, setPickedNewNames] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatPair = (record, familyMembers) => {
    return {
      giver: familyMembers[record.get('Giver')[0]],
      recipient: familyMembers[record.get('Recipient')[0]]
    };
  };

  React.useEffect(() => { 
    var localFamilyMembers = {};
    var localYearMap = {};

    base('Family Members').select().firstPage(function page(err, records) {
      if (err) { console.error(err); return; }
  
      records.forEach(function(record) {
        localFamilyMembers[record.id] = record.get('Name');
      });

      base('Drawings').select().eachPage(function page(records, fetchNextPage) {
        records.forEach(function(record) {
          const entryDate = new Date(record.get('Date'));
          const entryYear = entryDate.getFullYear();
          const pair = formatPair(record, localFamilyMembers);
          if(localYearMap.hasOwnProperty(entryYear)) {
            localYearMap[entryYear].push(pair)
          } else {
            localYearMap[entryYear] = [pair];
          }
        });
        
        fetchNextPage();
      }, function done(err) {
          if (err) { console.error(err); return; }
          var years = [];
          for (const year in localYearMap) {
            years.push(year);
          }

          const newYearStr = newYear.toString();
          if(!years.includes(newYearStr)) {
            years.push(newYearStr);
            localYearMap[newYearStr] = [];
          }
          years.reverse()
          setPastDrawings(years);
          setYearMap(localYearMap);
      });
    });
  }, [pickedNewNames]);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="tabs of past drawings"
      >
      {pastDrawings.map((year, index) => (
        <Tab label={year} {...a11yProps(index)} />
      ))}
      </Tabs>
      {pastDrawings.map((year, index) => (
        <TabPanel
          value={value}
          index={index}
          key={`tabpanel-${index}`}
          data={yearMap[year]}
          reloadMethod={setPickedNewNames} />
      ))}
      {!pastDrawings.length && (
        <Box m={3}>
          <CircularProgress />
        </Box>
      )}
    <Copyright m={3} />
  </div>
  )
}

export default IndexPage
